import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex-box van-hairline--bottom"
};
const _hoisted_2 = {
  style: {
    "margin": "var(--van-padding-md) 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  return _openBlock(), _createBlock(_component_van_form, {
    onSubmit: $options.onSubmit,
    onFailed: $options.onFailed,
    style: {
      "margin": "var(--van-padding-md)"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_field, {
      modelValue: $data.accountLoginFormData.number,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.accountLoginFormData.number = $event),
      placeholder: "请输入登录账号/手机号/邮箱",
      type: "text",
      rules: [{
        required: true,
        message: '请输入登录账号/手机号/邮箱'
      }]
    }, null, 8, ["modelValue"])]), _createVNode(_component_van_field, {
      modelValue: $data.accountLoginFormData.password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.accountLoginFormData.password = $event),
      placeholder: "请输入密码",
      clearable: "",
      type: "password",
      autocomplete: "off",
      rules: [{
        required: true,
        message: '请输入密码'
      }]
    }, null, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
      round: "",
      block: "",
      loading: $data.isLoading,
      type: "primary",
      "native-type": "submit"
    }, {
      default: _withCtx(() => [_createTextVNode("登 录")]),
      _: 1
    }, 8, ["loading"]), _createVNode(_component_van_button, {
      round: "",
      block: "",
      type: "default",
      style: {
        "margin-top": "var(--van-padding-md)"
      },
      "native-type": "button",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('changeLoginType'))
    }, {
      default: _withCtx(() => [_createTextVNode("手机号登录")]),
      _: 1
    }), _createVNode(_component_van_button, {
      round: "",
      block: "",
      type: "success",
      style: {
        "margin-top": "var(--van-padding-md)"
      },
      "native-type": "button",
      onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push('/regist'))
    }, {
      default: _withCtx(() => [_createTextVNode("去注册")]),
      _: 1
    })])]),
    _: 1
  }, 8, ["onSubmit", "onFailed"]);
}