import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_action_sheet = _resolveComponent("van-action-sheet");
  return _openBlock(), _createBlock(_component_van_action_sheet, {
    show: _ctx.isShown,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => _ctx.isShown = $event),
    actions: _ctx.actions,
    onSelect: _ctx.onSelect,
    "cancel-text": "取消",
    description: _ctx.title,
    "close-on-click-action": ""
  }, null, 8, ["show", "actions", "onSelect", "description"]);
}