import crypto from "@/util/encrypt";
export default {
  data() {
    return {
      isLoading: false,
      accountLoginFormData: {
        number: "",
        password: ""
      }
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      const encryptedData = crypto.encrypt(this.accountLoginFormData.password, "893e43e37a4a8beb", "893e43e37a4a8beb");
      this.$axios.numberLogin({
        name: this.accountLoginFormData.number,
        pass: encryptedData,
        invite_id: this.$store.state.invite_id
      }).then(res => {
        if (res.code === 1) {
          this.isLoading = false;
          this.$emit("success", res.data);

          // if (res.data.user_info.curr_team_id) {
          //   this.$router.push("/");
          // } else {
          //   // 创建团队的流程
          //   this.$notify({
          //     message: "当前登录账号下无团队，请先创建团队",
          //     type: "danger",
          //     duration: 1000,
          //     onClose: () => {
          //       this.isLoading = false;
          //       this.$emit("noTeam");
          //     },
          //   });
          // }
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    }
  }
};