import { ref, defineComponent } from "vue";
export default defineComponent({
  name: "CustomActionSheet",
  props: {
    // 可以传递一些配置项，如 actions
    actions: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: "标题"
    }
  },
  setup(props, {
    emit
  }) {
    const isShown = ref(false);
    const onSelect = action => {
      emit("select", action);
      // 根据需要关闭动作面板
      isShown.value = false;
    };

    // 对外暴露的方法
    const show = () => {
      isShown.value = true;
    };
    const hide = () => {
      isShown.value = false;
    };
    return {
      isShown,
      onSelect,
      show,
      hide
    };
  }
});