import CryptoJS from "crypto-js";

export default {
  /**
   * 接口数据加密函数
   * @param str string 需加密的json字符串
   * @param key string 加密key(16位)
   * @param iv string 加密向量(16位)
   * @return string 加密密文字符串
   */
  encrypt(str, key, iv) {
    //密钥16位
    key = CryptoJS.enc.Utf8.parse(key);
    //加密向量16位
    iv = CryptoJS.enc.Utf8.parse(iv);
    var encrypted = CryptoJS.AES.encrypt(str, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return encrypted.toString();
  },
};
